<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <div class="title">
                        <h1>{{ data.produkTitle }}</h1>
                    </div>
                </b-col>
            </b-row>
            <!-- produk desktop -->
            <div class="for_desktop" style="margin-bottom: 5rem">
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/bpom.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/mui.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/gmp.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/iso.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/haccp.png" width="50%" /></b-col>
                    <b-col cols="1"></b-col>
                </b-row>
                <div>
                    <b-row class="productTitle" @click="clickCarret('Health')">
                        <b-col cols="10" align="left">HEALTH & WELLNESS</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconHealth" aria-hidden="true"></i></b-col>
                    </b-row>
                    <div id="showProductDesktop1">
                        <transition name="fade">
                            <b-row v-if="showHealth">
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('bionadboost')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bionadboost-new.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('applescplus')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/applescplus.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('applesc25')" src="https://api.bisnisonlinebgs.com/uploads/images/product/apple25.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('applesc')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/applesc.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- detail health desktop 1 -->

                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioNADBoost == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12">
                                                <div v-for="(manfaat, index) in dataProduk.manfaat"
                                                    :key="manfaat[index]">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <h4>
                                                    <a href="https://bionadboost.co.id">Learn more &#8594;</a>
                                                </h4>
                                            </b-col>
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->

                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/tga.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/auslogo.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>

                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailApplePlus == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition> -->
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col> -->

                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProduk1">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/swiss-flag.png" />
                                                            </b-col>
                                                            <b-col cols="4" align="left">
                                                                <b>SWITZERLAND</b>
                                                                <p>CH715456B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/korea-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SOUTH KOREA</b>
                                                                <p>KR101470632B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2260829B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk2">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1985280B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9155916B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US8206721B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk3">
                                                    <b-col cols="12" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="right">
                                                                <img src="../../assets/images/icon/ww-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>WORLDWIDE</b>
                                                                <p>WO2015163156A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailApple == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12">
                                                <div v-for="(manfaat, index) in dataProduk.manfaat"
                                                    :key="manfaat[index]">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProduk1">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/swiss-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SWITZERLAND</b>
                                                                <p>CH715456B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/korea-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SOUTH KOREA</b>
                                                                <p>KR101470632B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2260829B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk2">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1985280B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9155916B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US8206721B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk3">
                                                    <b-col cols="12" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="right">
                                                                <img src="../../assets/images/icon/ww-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>WORLDWIDE</b>
                                                                <p>WO2015163156A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail health desktop 1 -->

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biomild')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biomild1.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- new -->
                                <!-- <b-col sm="12" align="left" v-if="displayDetailBioMild == 1">
                            <b-row class="detailProductNew" v-if="showManfaatProduk == 1">
                                <b-col cols="9" align="right" class="content">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                    <p v-html="dataProduk.deskripsi"></p>
                                    <img src="https://api.bisnisonlinebgs.com/uploads/images/product/lernmore.png" width="28%">
                                </b-col>
                            </b-row>
                            <b-row class="detailProductNew" v-if="showManfaatProduk == 0">
                                 <b-col cols="9">
                                    <h5>Manfaat</h5>
                                </b-col>
                                <b-col cols="9" align="left" class="content-manfaat" v-for="manfaat in moreManfaatProduk" :key="manfaat.id">
                                    <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                    <p><b-progress :value="value" :max="max"></b-progress></p>
                                </b-col>
                                <b-col cols="9">
                                    <h5>Bahan Aktif</h5>
                                </b-col>
                                <b-col cols="9">
                                    <b-row> 
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif1 in databahanAktif1" :key="bahanAktif1" style="list-style-type:none;">
                                                 <li><b>{{bahanAktif1}}</b></li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                             <ul v-for="bahanAktif2 in databahanAktif2" :key="bahanAktif2" style="list-style-type:none;">
                                                <li><b>{{bahanAktif2}}</b></li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif3 in databahanAktif3" :key="bahanAktif3" style="list-style-type:none;">
                                                <li><b>{{bahanAktif3}}</b></li>
                                            </ul>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col> -->
                                <!-- end new -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biomildcapsule')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biomildcapsule.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biogold')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biogold.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- detail health desktop 2 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioGold == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>

                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioMild == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition> -->
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioMildCapsule == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>

                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail health desktop 2 -->

                                <!-- <b-col sm="4" class="cardsContent">
                            <a href="#biofibrenize" @click="clickProduct('biofibrenize')"><img src="https://api.bisnisonlinebgs.com/uploads/images/product/biofibrenize.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth"></a>
                        </b-col> -->

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('kiseki')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/kiseki.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('bioinflavia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bioinflavia.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('biodiabeguard')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-diabeguard.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->

                                <!-- detail health desktop 3 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioInflavia == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioDiabeguard == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailKiseki == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>

                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <h4>
                                                    <a href="https://kiseki.co.id">Learn more &#8594;</a>
                                                </h4>
                                            </b-col>

                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/naha.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioFibrenize == 1">
                           <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/doctor-rec.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->
                                <!-- end detail health desktop 3 -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('bioollevia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-ollevia.jpeg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('biohypertenguard')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-hypertenguard.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->
                                <!-- detail health desktop 4 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioOllevia == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail health desktop 4 -->
                                <!-- detail health desktop 4 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioHypertenguard == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail health desktop 4 -->
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop1">
                        <b-row v-if="showHealth" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <!-- <b-row class="productTitle" @click="clickCarret('Beauty')">
                        <b-col cols="10" align="left">PERSONAL CARE & BEAUTY</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBeauty" aria-hidden="true"></i></b-col>
                    </b-row> -->
                    <div id="showProductDesktop2">
                        <transition name="fade">
                            <b-row v-if="showBeauty == 1">
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('bioadvanced')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bioadvance.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadBeauty">
                        </b-col> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biobenevia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/benevia.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadBeauty" />
                                </b-col>

                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('biofassiel')" src="https://api.bisnisonlinebgs.com/uploads/images/product/biofassiel.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadBeauty">
                        </b-col> -->
                                <!-- detail hair desktop 1 -->
                                <!-- <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioFassiel == 1">
                           <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/doctor-rec.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->

                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioAdvanced == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-row class="logoFlagProduk4">
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20170020796A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20080299092A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBenevia == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail hair desktop 1 -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biosiflevio')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biosiflevio.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadBeauty" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioSiflevio == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop2">
                        <b-row v-if="showBeauty" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <b-row class="productTitle" @click="clickCarret('BodyWeight')">
                        <b-col cols="10" align="left">BODY WEIGHT MANAGEMENT</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBodyWeight" aria-hidden="true"></i></b-col>
                    </b-row>

                    <div id="showProductDesktop3">
                        <transition name="fade">
                            <b-row v-if="showBodyWeight == 1">
                                <!-- <b-col sm="4" class="cardsContent" >
                            <img @click="clickProduct('biofit')" src="https://api.bisnisonlinebgs.com/uploads/images/product/biofit.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadWeight">
                        </b-col> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biorawgenic')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biorawgenic.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadWeight" />
                                </b-col>
                                <!--  <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('biofibrenize')" src="https://api.bisnisonlinebgs.com/uploads/images/product/biofibrenize.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->
                                <!-- detail body 1 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioRawgenic == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProduk1">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US6130321A</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1242123A2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2146708B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk4">
                                                    <b-col cols="6" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2117356B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="6" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9820504B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioFit == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-row class="logoFlagProduk4">
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US9358264B2</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20120172444A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioFibrenize == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-row class="logoFlagProduk2">
                                                <b-col cols="4" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20150025037A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="4" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US8617636B2</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="4" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/ww-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>WORLDWIDE</b>
                                                            <p>WO2015183729A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail body 1 -->
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop3">
                        <b-row v-if="showBodyWeight" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <!--  <b-row class="productTitle" @click="clickCarret('Accessories')">
                        <b-col cols="10" align="left">ACCESSORIES</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconAccessories" aria-hidden="true"></i></b-col>
                    </b-row> -->
                    <div id="showProductDesktop4">
                        <transition name="fade">
                            <b-row v-if="showAccessories == 1">
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biobracelet')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bracelet.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadAcc" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                            <a href="#bioneclace" @click="clickProduct('bioneclace')"><img src="https://api.bisnisonlinebgs.com/uploads/images/product/neclace.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biobeads')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/beads.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadAcc" />
                                </b-col>
                                <!-- end detail aksesoris 1 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBeads == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBracelet == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <!-- <h6>{{manfaat.desc}}</h6> -->
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioNeclace == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>

                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="3" align="center"></b-col>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                        <b-col cols="3" align="center"></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->
                                <!-- end detail aksesoris 1 -->
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop4">
                        <b-row v-if="showAccessories" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
            </div>
            <!-- end produk desktop -->

            <!-- produk tablet -->
            <div class="for_tablet" style="margin-bottom: 5rem">
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/bpom.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/mui.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/gmp.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/iso.png" width="50%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/haccp.png" width="50%" /></b-col>
                    <b-col cols="1"></b-col>
                </b-row>
                <div>
                    <b-row class="productTitle" @click="clickCarret('Health')">
                        <b-col cols="10" align="left">HEALTH & WELLNESS</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconHealth" aria-hidden="true"></i></b-col>
                    </b-row>
                    <div id="showProductDesktop1">
                        <transition name="fade">
                            <b-row v-if="showHealth">
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('bionadboost')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bionadboost-new.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('applescplus')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/applescplus.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('applesc')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/applesc.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- detail health desktop 1 -->

                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioNADBoost == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12">
                                                <div v-for="(manfaat, index) in dataProduk.manfaat"
                                                    :key="manfaat[index]">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <h4>
                                                    <a href="https://bionadboost.co.id">Learn more &#8594;</a>
                                                </h4>
                                            </b-col>
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->

                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/tga.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/auslogo.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>

                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailApplePlus == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition> -->
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col> -->

                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProduk1">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/swiss-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SWITZERLAND</b>
                                                                <p>CH715456B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/korea-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SOUTH KOREA</b>
                                                                <p>KR101470632B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2260829B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk2">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1985280B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9155916B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US8206721B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk3">
                                                    <b-col cols="12" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="right">
                                                                <img src="../../assets/images/icon/ww-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>WORLDWIDE</b>
                                                                <p>WO2015163156A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailApple == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12">
                                                <div v-for="(manfaat, index) in dataProduk.manfaat"
                                                    :key="manfaat[index]">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProduk1">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/swiss-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SWITZERLAND</b>
                                                                <p>CH715456B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/korea-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SOUTH KOREA</b>
                                                                <p>KR101470632B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2260829B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk2">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1985280B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9155916B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US8206721B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk3">
                                                    <b-col cols="12" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="right">
                                                                <img src="../../assets/images/icon/ww-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>WORLDWIDE</b>
                                                                <p>WO2015163156A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>

                                <!-- end detail health desktop 1 -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biomild')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biomild1.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- new -->
                                <!-- <b-col sm="12" align="left" v-if="displayDetailBioMild == 1">
                            <b-row class="detailProductNew" v-if="showManfaatProduk == 1">
                                <b-col cols="9" align="right" class="content">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                    <p v-html="dataProduk.deskripsi"></p>
                                    <img src="https://api.bisnisonlinebgs.com/uploads/images/product/lernmore.png" width="28%">
                                </b-col>
                            </b-row>
                            <b-row class="detailProductNew" v-if="showManfaatProduk == 0">
                                 <b-col cols="9">
                                    <h5>Manfaat</h5>
                                </b-col>
                                <b-col cols="9" align="left" class="content-manfaat" v-for="manfaat in moreManfaatProduk" :key="manfaat.id">
                                    <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                    <p><b-progress :value="value" :max="max"></b-progress></p>
                                </b-col>
                                <b-col cols="9">
                                    <h5>Bahan Aktif</h5>
                                </b-col>
                                <b-col cols="9">
                                    <b-row> 
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif1 in databahanAktif1" :key="bahanAktif1" style="list-style-type:none;">
                                                 <li><b>{{bahanAktif1}}</b></li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                             <ul v-for="bahanAktif2 in databahanAktif2" :key="bahanAktif2" style="list-style-type:none;">
                                                <li><b>{{bahanAktif2}}</b></li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif3 in databahanAktif3" :key="bahanAktif3" style="list-style-type:none;">
                                                <li><b>{{bahanAktif3}}</b></li>
                                            </ul>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col> -->
                                <!-- end new -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biomildcapsule')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biomildcapsule.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biogold')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biogold.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- detail health desktop 2 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioGold == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>

                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioMild == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition> -->
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioMildCapsule == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>

                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail health desktop 2 -->
                                <!-- <b-col sm="4" class="cardsContent">
                            <a href="#biofibrenize" @click="clickProduct('biofibrenize')"><img src="https://api.bisnisonlinebgs.com/uploads/images/product/biofibrenize.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth"></a>
                        </b-col> -->

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('kiseki')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/kiseki.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('bioinflavia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bioinflavia.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('biodiabeguard')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-diabeguard.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->
                                <!-- detail health desktop 3 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioInflavia == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioDiabeguard == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailKiseki == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <h4>
                                                    <a href="https://kiseki.co.id">Learn more &#8594;</a>
                                                </h4>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/naha.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioFibrenize == 1">
                           <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/doctor-rec.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->
                                <!-- end detail health desktop 3 -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('bioollevia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-ollevia.jpeg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('biohypertenguard')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-hypertenguard.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->
                                <!-- detail health desktop 4 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioOllevia == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail health desktop 4 -->
                                <!-- detail health desktop 4 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioHypertenguard == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail health desktop 4 -->
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop1">
                        <b-row v-if="showHealth" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <!-- <b-row class="productTitle" @click="clickCarret('Beauty')">
                        <b-col cols="10" align="left">PERSONAL CARE & BEAUTY</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBeauty" aria-hidden="true"></i></b-col>
                    </b-row> -->
                    <div id="showProductDesktop2">
                        <transition name="fade">
                            <b-row v-if="showBeauty == 1">
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('bioadvanced')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bioadvance.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadBeauty" />
                                </b-col>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biobenevia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/benevia.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadBeauty" />
                                </b-col>

                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProduct('biofassiel')" src="https://api.bisnisonlinebgs.com/uploads/images/product/biofassiel.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadBeauty">
                        </b-col> -->
                                <!-- detail hair desktop 1 -->
                                <!-- <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioFassiel == 1">
                           <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/doctor-rec.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->

                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioAdvanced == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-row class="logoFlagProduk4">
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20170020796A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20080299092A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBenevia == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail hair desktop 1 -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biosiflevio')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biosiflevio.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadBeauty" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioSiflevio == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop2">
                        <b-row v-if="showBeauty" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <b-row class="productTitle" @click="clickCarret('BodyWeight')">
                        <b-col cols="10" align="left">BODY WEIGHT MANAGEMENT</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBodyWeight" aria-hidden="true"></i></b-col>
                    </b-row>

                    <div id="showProductDesktop3">
                        <transition name="fade">
                            <b-row v-if="showBodyWeight == 1">
                                <!-- <b-col sm="4" class="cardsContent" >
                                    <img @click="clickProduct('biofit')" 
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biofit.jpg" 
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen" 
                                        @load="onImgLoadWeight">
                                </b-col> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biorawgenic')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biorawgenic.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadWeight" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biofibrenize')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biofibrenize.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth">
                                </b-col> -->
                                <!-- detail body 1 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioRawgenic == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProduk1">
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US6130321A</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1242123A2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2146708B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="logoFlagProduk4">
                                                    <b-col cols="6" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2117356B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="6" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9820504B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioFit == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-row class="logoFlagProduk4">
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US9358264B2</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="6" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20120172444A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioFibrenize == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-row class="logoFlagProduk2">
                                                <b-col cols="4" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US20150025037A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="4" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/usa-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>UNITED STATES</b>
                                                            <p>US8617636B2</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                                <b-col cols="4" align="center">
                                                    <b-row>
                                                        <b-col cols="5" align="center">
                                                            <img src="../../assets/images/icon/ww-flag.png" />
                                                        </b-col>
                                                        <b-col cols="7" align="left">
                                                            <b>WORLDWIDE</b>
                                                            <p>WO2015183729A1</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- end detail body 1 -->
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop3">
                        <b-row v-if="showBodyWeight" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <!-- <b-row class="productTitle" @click="clickCarret('Accessories')">
                        <b-col cols="10" align="left">ACCESSORIES</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconAccessories" aria-hidden="true"></i></b-col>
                    </b-row> -->
                    <div id="showProductDesktop4">
                        <transition name="fade">
                            <b-row v-if="showAccessories == 1">
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biobracelet')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bracelet.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadAcc" />
                                </b-col>
                                <!-- <b-col sm="4" class="cardsContent">
                            <a href="#bioneclace" @click="clickProduct('bioneclace')"><img src="https://api.bisnisonlinebgs.com/uploads/images/product/neclace.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProduct('biobeads')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/beads.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadAcc" />
                                </b-col>
                                <!-- end detail aksesoris 1 -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBeads == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProduk == 1">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProduk == 0" @click="readMoreManfaat()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 1" @click="kembaliManfaat()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBracelet == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProduk.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <p v-html="dataProduk.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <!-- <h6>{{manfaat.desc}}</h6> -->
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioNeclace == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>

                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="3" align="center"></b-col>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                        <b-col cols="3" align="center"></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->
                                <!-- end detail aksesoris 1 -->
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductDesktop4">
                        <b-row v-if="showAccessories" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
            </div>
            <!-- end produk tablet -->

            <!-- produk mobile -->
            <div class="for_mobile" style="margin-bottom: 5rem">
                <b-row>
                    <b-col cols="1"></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/bpom.png" width="100%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/mui.png" width="100%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/gmp.png" width="100%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/iso.png" width="100%" /></b-col>
                    <b-col cols="2"><img src="../../assets/images/icon/haccp.png" width="100%" /></b-col>
                    <b-col cols="1"></b-col>
                </b-row>
                <div>
                    <b-row class="productTitle" @click="clickCarret('Health')">
                        <b-col cols="10" align="left">HEALTH & WELLNESS</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconHealth" aria-hidden="true"></i></b-col>
                    </b-row>
                    <div id="showProductMobile1">
                        <transition name="fade">
                            <b-row v-if="showHealth == 1">
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioNADBoostMobile('bionadboost')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bionadboost-new.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioNADBoostMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioNADBoostMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12">
                                                <p v-html="dataProdukBioNADBoostMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12">
                                                <div v-for="(
                            manfaat, index
                          ) in dataProdukBioNADBoostMobile.manfaat" :key="manfaat[index]">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>

                                            <b-col cols="12" v-if="showManfaatProduk == 0">
                                                <h4>
                                                    <a href="https://bionadboost.co.id">Learn more &#8594;</a>
                                                </h4>
                                            </b-col>
                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioNADBoostMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->

                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/tga.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/auslogo.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductApplescMobile('applesc')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/applesc.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailAppleMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukAppleScMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12">
                                                <p v-html="dataProdukAppleScMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12">
                                                <div v-for="(
                            manfaat, index
                          ) in dataProdukAppleScMobile.manfaat" :key="manfaat[index]">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>

                                            <!-- <b-col cols="12" v-if="showManfaatProduk == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukAppleScMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProdukmobileContainer">
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/swiss-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SWITZERLAND</b>
                                                                <p>CH715456B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/korea-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SOUTH KOREA</b>
                                                                <p>KR101470632B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2260829B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1985280B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9155916B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US8206721B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/ww-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>WORLDWIDE</b>
                                                                <p>WO2015163156A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductApplePlusMobile('applescplus')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/applescplus.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailApplePlusMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukAppleScPlusMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukAppleScPlusMobile == 0">
                                                <p v-html="dataProdukAppleScPlusMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukAppleScPlusMobile == 0">
                                                <div v-for="manfaat in dataProdukAppleScPlusMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukAppleScPlusMobile == 1">
                                    <div v-for="manfaat in dataProdukAppleScPlusMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition> -->
                                            <!-- <b-col cols="12" v-if="showManfaatProdukAppleScPlusMobile == 0" @click="readMoreManfaatAppleScPlusMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukAppleScPlusMobile == 1" @click="kembaliManfaatAppleScPlusMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukAppleScPlusMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukAppleScPlusMobile == 0">
                                    <ul v-for="(bahanAktif,index) in moreManfaatProdukAppleScPlusMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProdukmobileContainer">
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/swiss-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SWITZERLAND</b>
                                                                <p>CH715456B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/korea-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>SOUTH KOREA</b>
                                                                <p>KR101470632B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2260829B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1985280B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9155916B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US8206721B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/ww-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>WORLDWIDE</b>
                                                                <p>WO2015163156A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioMildeMobile('biomild')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biomild1.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioMildMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioScMildMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioMildMobile == 0">
                                                <p v-html="dataProdukBioScMildMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioMildMobile == 0">
                                                <div v-for="manfaat in dataProdukBioScMildMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioMildMobile == 1">
                                    <div v-for="manfaat in dataProdukBioScMildMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioMildMobile == 0" @click="readMoreManfaatBioMildMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioMildMobile == 1" @click="kembaliManfaatBioMildMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioMildMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioMildMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioScMildMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioMildCapsuleMobile('biomildcapsule')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biomildcapsule.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioMildCapsuleMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>
                                                    {{ dataProdukBioScMildCapsuleMobile.nama_produk }}
                                                </h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioMildCapsule == 0">
                                                <p v-html="dataProdukBioScMildCapsuleMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioMildCapsule == 0">
                                                <div v-for="manfaat in dataProdukBioScMildCapsuleMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioMildCapsule == 1">
                                    <div v-for="manfaat in dataProdukBioScMildCapsuleMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioMildCapsule == 0" @click="readMoreManfaatBioMildCapsule()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioMildCapsule == 1" @click="kembaliManfaatBioMildCapsule()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioMildCapsule == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioMildCapsule == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioScMildCapsuleMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioGoldMobile('biogold')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biogold.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioGoldMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioScGoldMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioGoldMobile == 0">
                                                <p v-html="dataProdukBioScGoldMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioGoldMobile == 0">
                                                <div v-for="manfaat in dataProdukBioScGoldMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioGoldMobile == 1">
                                    <div v-for="manfaat in dataProdukBioScGoldMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioGoldMobile == 0" @click="readMoreManfaatBioGoldMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioGoldMobile == 1" @click="kembaliManfaatBioGoldMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioGoldMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioGoldMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioScGoldMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <b-col sm="4" class="cardsContent">
                            <a href="#biofibrenize" @click="clickProductBioFibrenizeMobile('biofibrenize')"><img src="https://api.bisnisonlinebgs.com/uploads/images/product/biofibrenize.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth"></a>
                        </b-col>
                        <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioFibrenizeMobile == 1">
                           <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProdukBioScFibrenizeMobile.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioFibrenizeMobile == 0">
                                    <p v-html="dataProdukBioScFibrenizeMobile.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioFibrenizeMobile == 0">
                                    <div v-for="manfaat in dataProdukBioScFibrenizeMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/doctor-rec.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductKisekiMobile('kiseki')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/kiseki.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailKisekiMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukKisekiMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukKisekiMobile == 0">
                                                <p v-html="dataProdukKisekiMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukKisekiMobile == 0">
                                                <div v-for="manfaat in dataProdukKisekiMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukKisekiMobile == 0">
                                                <h4>
                                                    <a href="https://kiseki.co.id">Learn more &#8594;</a>
                                                </h4>
                                            </b-col>

                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/naha.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioInflaviaMobile('bioinflavia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bioinflavia.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioInflaviaMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioInflaviaMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0">
                                                <p v-html="dataProdukBioInflaviaMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0">
                                                <div v-for="manfaat in dataProdukBioInflaviaMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1">
                                    <div v-for="manfaat in dataProdukBioInflaviaMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0" @click="readMoreManfaatBioInflaviaMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1" @click="kembaliManfaatBioInflaviaMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioInflaviaMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProductBioDiabeguardMobile('biodiabeguard')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-diabeguard.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioDiabeguardMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioDiabeguardMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioDiabeguardMobile == 0">
                                                <p v-html="dataProdukBioDiabeguardMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioDiabeguardMobile == 0">
                                                <div v-for="manfaat in dataProdukBioDiabeguardMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1">
                                    <div v-for="manfaat in dataProdukBioInflaviaMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0" @click="readMoreManfaatBioInflaviaMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1" @click="kembaliManfaatBioInflaviaMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioInflaviaMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>

                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioOlleviaMobile('bioollevia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-ollevia.jpeg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioOlleviaMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioOlleviaMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioOlleviaMobile == 0">
                                                <p v-html="dataProdukBioOlleviaMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioOlleviaMobile == 0">
                                                <div v-for="manfaat in dataProdukBioOlleviaMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1">
                                    <div v-for="manfaat in dataProdukBioInflaviaMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0" @click="readMoreManfaatBioInflaviaMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1" @click="kembaliManfaatBioInflaviaMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioInflaviaMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProductBioHypertenguardMobile('biohypertenguard')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bio-hypertenguard.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadHealth">
                        </b-col> -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioHypertenguardMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>
                                                    {{ dataProdukBioHypertenguardMobile.nama_produk }}
                                                </h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioHypertenguardMobile == 0">
                                                <p v-html="dataProdukBioHypertenguardMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioHypertenguardMobile == 0">
                                                <div v-for="manfaat in dataProdukBioHypertenguardMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1">
                                    <div v-for="manfaat in dataProdukBioInflaviaMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0" @click="readMoreManfaatBioInflaviaMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 1" @click="kembaliManfaatBioInflaviaMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioInflaviaMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioInflaviaMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductMobile1">
                        <b-row v-if="showHealth" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <!-- <b-row class="productTitle" @click="clickCarret('Beauty')">
                        <b-col cols="10" align="left">PERSONAL CARE & BEAUTY</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBeauty" aria-hidden="true"></i></b-col>
                    </b-row> -->
                    <div id="showProductMobile2">
                        <transition name="fade">
                            <b-row v-if="showBeauty == 1">
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProductBioAdvanceMobile('bioadvanced')" src="https://api.bisnisonlinebgs.com/uploads/images/product/bioadvance.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadBeauty">
                        </b-col>
                        <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioAdvancedMobile == 1">
                           <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProdukBioAdvanceMobile.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioAdvanceMobile == 0">
                                    <p v-html="dataProdukBioAdvanceMobile.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioAdvanceMobile == 0">
                                    <div v-for="manfaat in dataProdukBioAdvanceMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                <b-col cols="12" align="center">
                                    <b-row class="logoFlagProdukmobileContainer">
                                        <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                            <b-row>
                                                <b-col cols="5" align="center">
                                                    <img src="../../assets/images/icon/usa-flag.png">
                                                </b-col>
                                                <b-col cols="7" align="left">
                                                    <b>UNITED STATES</b>
                                                    <p>US20170020796A1</p>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                            <b-row>
                                                <b-col cols="5" align="center">
                                                    <img src="../../assets/images/icon/usa-flag.png">
                                                </b-col>
                                                <b-col cols="7" align="left">
                                                    <b>UNITED STATES</b>
                                                    <p>US20080299092A1</p>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                     </b-row>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/best-seller.png"></h4></b-col>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/doctor-rec.png"></h4></b-col>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="3" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioBeneviaMobile('biobenevia')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/benevia.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadBeauty" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBeneviaMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioBeneviaMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioBeneviaMobile == 0">
                                                <p v-html="dataProdukBioBeneviaMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioBeneviaMobile == 0">
                                                <div v-for="manfaat in dataProdukBioBeneviaMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <b-col sm="4" class="cardsContent">
                            <img @click="clickProductBioFassielMobile('biofassiel')" src="https://api.bisnisonlinebgs.com/uploads/images/product/biofassiel.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen" @load="onImgLoadBeauty">
                        </b-col>
                        <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioFassielMobile == 1">
                           <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProdukBioFassielMobile.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioFassielMobile == 0">
                                    <p v-html="dataProdukBioFassielMobile.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioFassielMobile == 0">
                                    <div v-for="manfaat in dataProdukBioFassielMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/doctor-rec.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="4" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioSiflevioMobile('biosiflevio')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biosiflevio.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadBeauty" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioSiflevioMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioSiflevioMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioSiflevioMobile == 0">
                                                <p v-html="dataProdukBioSiflevioMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioSiflevioMobile == 0">
                                                <div v-for="manfaat in dataProdukBioSiflevioMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductMobile2">
                        <b-row v-if="showBeauty" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                    <b-row class="productTitle" @click="clickCarret('BodyWeight')">
                        <b-col cols="10" align="left">BODY WEIGHT MANAGEMENT</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBodyWeight" aria-hidden="true"></i></b-col>
                    </b-row>
                    <div id="showProductMobile3">
                        <transition name="fade">
                            <b-row v-if="showBodyWeight == 1">
                                <!-- <b-col sm="4" class="cardsContent" >
                                    <img @click="clickProductBioFitMobile('biofit')" 
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biofit.jpg" 
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen" 
                                        @load="onImgLoadWeight">
                                </b-col> -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioFitMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioFitMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioFitMobile == 0">
                                                <p v-html="dataProdukBioFitMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioFitMobile == 0">
                                                <div v-for="manfaat in dataProdukBioFitMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioFitMobile == 1">
                                    <div v-for="manfaat in dataProdukBioFitMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioFitMobile == 0" @click="readMoreManfaatBioFitMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioFitMobile == 1" @click="kembaliManfaatBioFitMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioFitMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioFitMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioFitMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProdukmobileContainer">
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9358264B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US20120172444A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioRawgenicMobile('biorawgenic')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biorawgenic.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadWeight" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioRawgenicMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioRawgenicMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioRawgenicMobile == 0">
                                                <p v-html="dataProdukBioRawgenicMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioRawgenicMobile == 0">
                                                <div v-for="manfaat in dataProdukBioRawgenicMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioRawgenicMobile == 1">
                                    <div v-for="manfaat in dataProdukBioRawgenicMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioRawgenicMobile == 0" @click="readMoreManfaatBioRawgenicMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioRawgenicMobile == 1" @click="kembaliManfaatBioRawgenicMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioRawgenicMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioRawgenicMobile == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioRawgenicMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProdukmobileContainer">
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US6130321A</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP1242123A2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2146708B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/eroupe-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>EUROPE</b>
                                                                <p>EP2117356B1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US9820504B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/best-seller.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="3" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                               <!--  <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioFibrenizeMobile('biofibrenize')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/biofibrenize.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadHealth" />
                                </b-col> -->
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioFibrenizeMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>
                                                    {{ dataProdukBioScFibrenizeMobile.nama_produk }}
                                                </h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioFibrenizeMobile == 0">
                                                <p v-html="dataProdukBioScFibrenizeMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioFibrenizeMobile == 0">
                                                <div v-for="manfaat in dataProdukBioScFibrenizeMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center">
                                                <b-row class="logoFlagProdukmobileContainer">
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US20150025037A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/usa-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>UNITED STATES</b>
                                                                <p>US8617636B2</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="logoFlagProdukmobile" align="center">
                                                        <b-row>
                                                            <b-col cols="5" align="center">
                                                                <img src="../../assets/images/icon/ww-flag.png" />
                                                            </b-col>
                                                            <b-col cols="7" align="left">
                                                                <b>WORLDWIDE</b>
                                                                <p>WO2015183729A1</p>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/mui.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/bpom.png" />
                                                        </h4>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductMobile3">
                        <b-row v-if="showBodyWeight" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
                <div>
                   <!--  <b-row class="productTitle" @click="clickCarret('Accessories')">
                        <b-col cols="10" align="left">ACCESSORIES</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconAccessories" aria-hidden="true"></i></b-col>
                    </b-row> -->
                    <div id="showProductMobile4">
                        <transition name="fade">
                            <b-row v-if="showAccessories == 1">
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioBraceletMobile('biobracelet')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/bracelet.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadAcc" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBraceletMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioBraceletMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioBracelet == 0">
                                                <p v-html="dataProdukBioBraceletMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioBracelet == 0">
                                                <div v-for="manfaat in dataProdukBioBraceletMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                                <!-- <b-col sm="4" class="cardsContent">
                            <a href="#bioneclace" @click="clickProduct('bioneclace')"><img src="https://api.bisnisonlinebgs.com/uploads/images/product/neclace.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <transition name="fade">
                        <b-col sm="12" align="left" v-if="displayDetailBioNeclace == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <div v-for="manfaat in dataProduk.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                 <b-col cols="12" align="center" class="logoDetailProduk">
                                    <b-row>
                                        <b-col cols="6" align="center"><h4><img src="../../assets/images/icon/mui.png"></h4></b-col>
                                        <b-col cols="6" align="center"><h4><img src="../../assets/images/icon/bpom.png"></h4></b-col>
                                    </b-row>
                                 </b-col>
                            </b-row>
                        </b-col>
                        </transition> -->
                                <b-col sm="4" class="cardsContent">
                                    <img @click="clickProductBioBeadsMobile('biobeads')"
                                        src="https://api.bisnisonlinebgs.com/uploads/images/product/beads.jpg"
                                        width="100%" alt="Product Biogreen" desc="Product Biogreen"
                                        @load="onImgLoadAcc" />
                                </b-col>
                                <transition name="fade">
                                    <b-col sm="12" align="left" v-if="displayDetailBioBeadsMobile == 1">
                                        <b-row class="detailProduct">
                                            <b-col cols="12">
                                                <h4>{{ dataProdukBioBeadsMobile.nama_produk }}</h4>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioBeadsMobile == 0">
                                                <p v-html="dataProdukBioBeadsMobile.deskripsi"></p>
                                            </b-col>
                                            <b-col cols="12" v-if="showManfaatProdukBioBeadsMobile == 0">
                                                <div v-for="manfaat in dataProdukBioBeadsMobile.manfaat"
                                                    :key="manfaat.id">
                                                    <h5>
                                                        {{ manfaat.title }}
                                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </h5>
                                                    <p>
                                                        <b-progress :value="value" :max="max"></b-progress>
                                                    </p>
                                                </div>
                                            </b-col>
                                            <!-- <transition name="fade">
                                <b-col cols="12" v-if="showManfaatProdukBioBeadsMobile == 1">
                                    <div v-for="manfaat in dataProdukBioBeadsMobile.manfaat" :key="manfaat.id">
                                        <h5>{{manfaat.title}} <i class="fa fa-chevron-right" aria-hidden="true"></i></h5>
                                        <h6>{{manfaat.desc}}</h6>
                                        <p><b-progress :value="value" :max="max"></b-progress></p>
                                    </div>
                                </b-col>
                                </transition>
                                <b-col cols="12" v-if="showManfaatProdukBioBeadsMobile == 0" @click="readMoreManfaatBioBeadsMobile()"><h5>READ MORE <i class="fa fa-chevron-right" aria-hidden="true"></i></h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioBeadsMobile == 1" @click="kembaliManfaatBioBeadsMobile()"><h5><i class="fa fa-chevron-left" aria-hidden="true"></i> BACK</h5></b-col>
                                <b-col cols="12" v-if="showManfaatProdukBioBeadsMobile == 0"><h4>BAHAN AKTIF</h4></b-col>
                                <b-col cols="12" v-if="showManfaatProduk == 0">
                                    <ul v-for="(bahanAktif,index) in dataProdukBioBeadsMobile.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col> -->
                                            <b-col cols="12" align="center" class="logoDetailProduk">
                                                <b-row>
                                                    <b-col cols="4" align="center">
                                                        <h4>
                                                            <img src="../../assets/images/icon/doctor-rec.png" />
                                                        </h4>
                                                    </b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                    <b-col cols="4" align="center"></b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </transition>
                            </b-row>
                        </transition>
                    </div>
                    <!-- loading -->
                    <div id="showLoadingProductMobile4">
                        <b-row v-if="showAccessories" id="loadingProduct">
                            <!-- <b-col cols="12" align="center" style="margin-top:1rem"><img src="../../assets/images/loading-proses.gif" width="80"></b-col> -->
                        </b-row>
                    </div>
                </div>
            </div>
            <!-- end produk mobile -->

            <!-- <div class="for_tablet" style="margin-bottom:3rem">
                 <div>
                    <b-row class="productTitle" @click="clickCarret('Health')">
                        <b-col cols="11" align="left">HEALTH & WELLNESS</b-col>
                        <b-col cols="1" align="right"><i :class="carretIconHealth" aria-hidden="true"></i></b-col>
                    </b-row>
                    <b-row v-if="showHealth == 1">
                        <b-col sm="4" class="cardsContent">
                            <a href="#applesc" @click="clickProduct('applesc')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-1.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailApple == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="4" class="cardsContent">
                            <a href="#applesc25" @click="clickProduct('applesc25')"><img src="../../assets/images/applesc25.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailApple25 == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="4" class="cardsContent">
                            <a href="#applescplus" @click="clickProduct('applescplus')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-2.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailApplePlus == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="4" class="cardsContent">
                            <a href="#biomild" @click="clickProduct('biomild')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-4.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioMild == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="4" class="cardsContent" >
                            <a href="#biomildcapsule" @click="clickProduct('biomildcapsule')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-9.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioMildCapsule == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="4" class="cardsContent">
                            <a href="#biogold" @click="clickProduct('biogold')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-7.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioGold == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="4" class="cardsContent">
                            <a href="#biofibrenize" @click="clickProduct('biofibrenize')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-10.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioFibrenize == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="4" class="cardsContent">
                            <a href="#bioinflavia" @click="clickProduct('bioinflavia')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-14.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioInflavia == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div>
                    <b-row class="productTitle" @click="clickCarret('Beauty')">
                        <b-col cols="11" align="left">PERSONAL CARE & BEAUTY</b-col>
                        <b-col cols="1" align="right"><i :class="carretIconBeauty" aria-hidden="true"></i></b-col>
                    </b-row>
                    <b-row v-if="showBeauty == 1">
                        <b-col sm="4" class="cardsContent">
                        <a href="#bioadvanced" @click="clickProduct('bioadvanced')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-6.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioAdvanced == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col sm="4" class="cardsContent">
                            <a href="#biobenevia" @click="clickProduct('biobenevia')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-5.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioBenevia == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col sm="4" class="cardsContent">
                            <a href="#biofassiel" @click="clickProduct('biofassiel')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-8.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioFassiel == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col sm="4" class="cardsContent">
                            <a href="#biosiflevio" @click="clickProduct('biosiflevio')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-15.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioSiflevio == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>
                </div>
                <div>
                    <b-row class="productTitle" @click="clickCarret('BodyWeight')">
                        <b-col cols="11" align="left">BODY WEIGHT MANAGEMENT</b-col>
                        <b-col cols="1" align="right"><i :class="carretIconBodyWeight" aria-hidden="true"></i></b-col>
                    </b-row>
                    <b-row v-if="showBodyWeight == 1">
                        <b-col sm="4" class="cardsContent" >
                            <a href="#biofit" @click="clickProduct('biofit')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-3.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioFit == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col sm="4" class="cardsContent">
                            <a href="#biorawgenic" @click="clickProduct('biorawgenic')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-12.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
                        <b-col sm="12" align="left" v-if="displayDetailBioRawgenic == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>
                </div>
                <div>
                    <b-row class="productTitle" @click="clickCarret('Accessories')">
                        <b-col cols="11" align="left">ACCESSORIES</b-col>
                        <b-col cols="1" align="right"><i :class="carretIconAccessories" aria-hidden="true"></i></b-col>
                    </b-row>
                    <b-row v-if="showAccessories == 1">
                        <b-col sm="4" class="cardsContent" >
                            <a href="#biobracelet" @click="clickProduct('biobracelet')"><img src="../../assets/images/bio_bracelet.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>
    
                        <b-col sm="12" align="left" v-if="displayDetailBioBracelet == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col sm="4" class="cardsContent">
                            <a href="#bioneclace" @click="clickProduct('bioneclace')"><img src="../../assets/images/necklace-resized-.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>

                        <b-col sm="12" align="left" v-if="displayDetailBioNeclace == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>


                        <b-col sm="4" class="cardsContent">
                            <a href="#biobeads" @click="clickProduct('biobeads')"><img src="../../assets/images/cakra_beads.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a>
                        </b-col>

                        <b-col sm="12" align="left" v-if="displayDetailBioBeads == 1">
                            <b-row class="detailProduct">
                                <b-col cols="12">
                                    <h4>{{dataProduk.nama_produk}}</h4>
                                </b-col>
                                <b-col cols="12">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12">
                                    <ul v-for="(bahanAktif,index) in dataProduk.bahan_aktif" :key="bahanAktif[index]">
                                        <li>{{bahanAktif}}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>
                </div>
            </div> -->
            <!-- <div class="for_mobile" style="margin-bottom:2rem">
            <b-row>
                <b-col sm="12">
                    <b-row class="productTitle" @click="clickCarret('Health')">
                        <b-col cols="10" align="left">HEALTH & WELLNESS</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconHealth" aria-hidden="true"></i></b-col>
                    </b-row>
                    <b-row v-if="showHealth == 1">
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#applesc" @click="clickProduct('applesc')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-1.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"> <a href="#applescplus" @click="clickProduct('applescplus')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-2.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                    </b-row>
                    <b-row v-if="showHealth == 1">
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biomild" @click="clickProduct('biomild')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-4.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biogold" @click="clickProduct('biogold')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-7.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                    </b-row>
                    <b-row v-if="showHealth == 1">
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#bioinflavia" @click="clickProduct('bioinflavia')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-14.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biomildcapsule" @click="clickProduct('biomildcapsule')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-9.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                    </b-row>
                    <b-row v-if="showHealth == 1">
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biofibrenize" @click="clickProduct('biofibrenize')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-10.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                    </b-row>
                    <b-row class="productTitle" @click="clickCarret('Beauty')">
                        <b-col cols="10" align="left">PERSONAL CARE & BEAUTY</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBeauty" aria-hidden="true"></i></b-col>
                    </b-row>
                    <b-row v-if="showBeauty == 1">
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#bioadvanced" @click="clickProduct('bioadvanced')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-6.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biobenevia" @click="clickProduct('biobenevia')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-5.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>             
                    </b-row>
                    <b-row v-if="showBeauty == 1">
                          <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biofassiel" @click="clickProduct('biofassiel')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-8.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                         <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biosiflevio" @click="clickProduct('biosiflevio')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-15.jpeg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                    </b-row>
                    <b-row class="productTitle" @click="clickCarret('BodyWeight')">
                        <b-col cols="10" align="left">BODY WEIGHT MANAGEMENT</b-col>
                        <b-col cols="2" align="right"><i :class="carretIconBodyWeight" aria-hidden="true"></i></b-col>
                    </b-row>
                    <b-row v-if="showBodyWeight == 1">
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biofit" @click="clickProduct('biofit')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-3.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>
                        <b-col cols="6" class="cardsContent" @click="$bvModal.show('bv-modal-example')"><a href="#biorawgenic" @click="clickProduct('biorawgenic')"><img src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/product-12.jpg" width="100%" alt="Product Biogreen" desc="Product Biogreen"></a></b-col>    
                    </b-row>
                </b-col>
            </b-row>
            </div> -->
        </b-container>
        <!-- modal -->
        <b-modal id="bv-modal-example" size="lg" hide-header hide-footer>
            <div :class="[classProduk]">
                <b-container fluid class="popUpProduk">
                    <b-row>
                        <b-col cols="12" class="popUpProdukContent" align="left">
                            <b-row>
                                <b-col cols="11" class="popUpProdukContentTitle for_desktop">
                                    <h4>{{ dataProduk.nama_produk }}</h4>
                                </b-col>
                                <b-col cols="1" class="popUpProdukContentTitle for_desktop"
                                    @click="$bvModal.hide('bv-modal-example')">
                                    <i class="fa fa-times-circle" style="font-size: 30px" aria-hidden="true"></i>
                                </b-col>
                                <b-col cols="11" class="popUpProdukContentTitle for_tablet">
                                    <h4>{{ dataProduk.nama_produk }}</h4>
                                </b-col>
                                <b-col cols="1" class="popUpProdukContentTitle for_tablet"
                                    @click="$bvModal.hide('bv-modal-example')">
                                    <i class="fa fa-times-circle" style="font-size: 30px" aria-hidden="true"></i>
                                </b-col>
                                <b-col cols="10" class="popUpProdukContentTitle for_mobile">
                                    <h4>{{ dataProduk.nama_produk }}</h4>
                                </b-col>
                                <b-col cols="2" class="popUpProdukContentTitle for_mobile"
                                    @click="$bvModal.hide('bv-modal-example')">
                                    <i class="fa fa-times-circle" style="font-size: 30px" aria-hidden="true"></i>
                                </b-col>
                                <b-col cols="12" v-if="displayContent == 1" class="popUpProdukContentDesc">
                                    <p v-html="dataProduk.deskripsi"></p>
                                </b-col>
                                <b-col cols="12" v-if="displayBioBenevia == 1">
                                    <ul>
                                        <li @click="clickProduct('beneviaTooth')">
                                            <b>Bio SC Benevia Tooth Paste</b>
                                        </li>
                                        <li @click="clickProduct('beneviaBody')">
                                            <b>Bio SC Benevia Body Wash</b>
                                        </li>
                                        <li @click="clickProduct('beneviaFacial')">
                                            <b>Bio SC Benevia Facial Wash</b>
                                        </li>
                                        <li @click="clickProduct('beneviaCond')">
                                            <b>Bio SC Benevia Conditioner</b>
                                        </li>
                                        <li @click="clickProduct('beneviaShampo')">
                                            <b>Bio SC Benevia Shampoo</b>
                                        </li>
                                    </ul>
                                </b-col>
                                <b-col cols="12" v-if="displayContent == 1 && displayBioBenevia == 0"
                                    class="popUpProdukContentTitle" @click="manfaat()">
                                    <b>MANFAAT
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i></b>
                                </b-col>
                                <b-col cols="12" v-if="displayContent == 0">
                                    <ul v-for="(manfaat, index) in dataProduk.manfaat" :key="manfaat[index]">
                                        <li>
                                            <b>{{ manfaat.title }}</b> <br />{{ manfaat.desc }}
                                        </li>
                                    </ul>
                                </b-col>
                                <b-col cols="12" v-if="displayContent == 0" class="popUpProdukContentTitle"
                                    @click="kembali()">
                                    <b><i class="fa fa-chevron-left" aria-hidden="true"></i>
                                        KEMBALI</b>
                                </b-col>
                                <b-col cols="12" v-if="displayContent == 1 && displayBioBenevia == 0"
                                    class="popUpProdukContentTitle">
                                    <b>BAHAN AKTIF</b>
                                </b-col>
                                <b-col cols="12" v-if="displayContent == 1" class="for_desktop">
                                    <b-row>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif1 in databahanAktif1" :key="bahanAktif1"
                                                style="list-style-type: none">
                                                <li>
                                                    <b>{{ bahanAktif1 }}</b>
                                                </li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif2 in databahanAktif2" :key="bahanAktif2"
                                                style="list-style-type: none">
                                                <li>
                                                    <b>{{ bahanAktif2 }}</b>
                                                </li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif3 in databahanAktif3" :key="bahanAktif3"
                                                style="list-style-type: none">
                                                <li>
                                                    <b>{{ bahanAktif3 }}</b>
                                                </li>
                                            </ul>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" v-if="displayContent == 1" class="for_tablet">
                                    <b-row>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif1 in databahanAktif1" :key="bahanAktif1"
                                                style="list-style-type: none">
                                                <li>
                                                    <b>{{ bahanAktif1 }}</b>
                                                </li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif2 in databahanAktif2" :key="bahanAktif2"
                                                style="list-style-type: none">
                                                <li>
                                                    <b>{{ bahanAktif2 }}</b>
                                                </li>
                                            </ul>
                                        </b-col>
                                        <b-col cols="4">
                                            <ul v-for="bahanAktif3 in databahanAktif3" :key="bahanAktif3"
                                                style="list-style-type: none">
                                                <li>
                                                    <b>{{ bahanAktif3 }}</b>
                                                </li>
                                            </ul>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <!-- <b-col cols="12" v-if="displayContent == 0 && displayBioBenevia == 1" class="popUpProdukContentTitle" @click="clickProduct('kembaliBenevia')">
                                <b><i class="fa fa-chevron-left" aria-hidden="true"></i> KEMBALI</b>
                            </b-col> -->
                                <b-col cols="12" v-if="displayContent == 1" class="for_mobile">
                                    <ul v-for="(bahanAktifMobile, index) in dataProduk.bahan_aktif"
                                        :key="bahanAktifMobile[index]" style="list-style-type: none">
                                        <li>
                                            <b>{{ bahanAktifMobile }}</b>
                                        </li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            bahasa: "id",
            data: {
                produkTitle: null,
            },
            displayContent: 0,
            dataProduk: [],
            classProduk: null,
            databahanAktif1: [],
            databahanAktif2: [],
            databahanAktif3: [],
            displayBioBenevia: 0,
            carretIconHealth: "fa fa-caret-down",
            carretIconBeauty: "fa fa-caret-down",
            carretIconBodyWeight: "fa fa-caret-down",
            carretIconAccessories: "fa fa-caret-down",
            showHealth: 0,
            showBeauty: 0,
            showBodyWeight: 0,
            showAccessories: 0,

            displayDetailApple: 0,
            displayDetailBioNADBoost: 0,
            displayDetailApplePlus: 0,
            displayDetailBioMild: 0,
            displayDetailBioMildCapsule: 0,
            displayDetailBioGold: 0,
            displayDetailBioFibrenize: 0,
            displayDetailBioInflavia: 0,
            displayDetailBioDiabeguard: 0,
            displayDetailKiseki: 0,
            displayDetailBioOllevia: 0,
            displayDetailBioHypertenguard: 0,

            displayDetailBioAdvanced: 0,
            displayDetailBioBenevia: 0,
            displayDetailBioFassiel: 0,
            displayDetailBioSiflevio: 0,

            displayDetailBioFit: 0,
            displayDetailBioRawgenic: 0,

            displayDetailBioBracelet: 0,
            displayDetailBioNeclace: 0,
            displayDetailBioBeads: 0,

            value: 0,
            max: 100,

            showManfaatProduk: 0,
            dataDetailProduct: [],
            moreManfaatProduk: [],

            moreManfaatProdukApplePlus: [],

            displayDetailAppleMobile: 0,
            displayDetailBioNADBoostMobile: 0,
            displayDetailApplePlusMobile: 0,
            displayDetailBioMildMobile: 0,
            displayDetailBioMildCapsuleMobile: 0,
            displayDetailBioGoldMobile: 0,
            displayDetailBioFibrenizeMobile: 0,
            displayDetailBioInflaviaMobile: 0,
            displayDetailBioAdvancedMobile: 0,
            displayDetailBioBeneviaMobile: 0,
            displayDetailBioFassielMobile: 0,
            displayDetailBioSiflevioMobile: 0,
            displayDetailBioFitMobile: 0,
            displayDetailBioRawgenicMobile: 0,
            displayDetailBioBraceletMobile: 0,
            displayDetailBioNeclaceMobile: 0,
            displayDetailBioBeadsMobile: 0,
            displayDetailBioDiabeguardMobile: 0,
            displayDetailKisekiMobile: 0,
            displayDetailBioOlleviaMobile: 0,
            displayDetailBioHypertenguardMobile: 0,

            dataProdukAppleScMobile: [],
            classProdukAppleSc: [],
            databahanAktif1AppleScMobile: [],
            databahanAktif2AppleScMobile: [],
            databahanAktif3AppleScMobile: [],
            moreManfaatProdukAppleScMobile: [],

            dataProdukBioNADBoostMobile: [],
            classProdukBioNADBoost: [],
            databahanAktif1BioNADBoostMobile: [],
            databahanAktif2BioNADBoostMobile: [],
            databahanAktif3BioNADBoostMobile: [],
            moreManfaatProdukBioNADBoostMobile: [],

            dataProdukAppleScPlusMobile: [],
            classProdukAppleScPlus: [],
            databahanAktif1AppleScPlusMobile: [],
            databahanAktif2AppleScPlusMobile: [],
            databahanAktif3AppleScPlusMobile: [],
            moreManfaatProdukAppleScPlusMobile: [],

            dataProdukBioScMildMobile: [],
            classProdukBioScMild: [],
            databahanAktif1BioScMildMobile: [],
            databahanAktif2BioScMildMobile: [],
            databahanAktif3BioScMildMobile: [],
            moreManfaatProdukBioScMildMobile: [],

            dataProdukBioScMildCapsuleMobile: [],
            classProdukBioScMildCapsule: [],
            databahanAktif1BioScMildCapsuleMobile: [],
            databahanAktif2BioScMildCapsuleMobile: [],
            databahanAktif3BioScMildCapsuleMobile: [],
            moreManfaatProdukBioScMildCapsuleMobile: [],

            dataProdukBioScGoldMobile: [],
            classProdukBioScGold: [],
            databahanAktif1BioScGoldMobile: [],
            databahanAktif2BioScGoldMobile: [],
            databahanAktif3BioScGoldMobile: [],
            moreManfaatProdukBioScGoldMobile: [],

            dataProdukBioScFibrenizeMobile: [],
            classProdukBioScFibrenize: [],
            databahanAktif1BioScFibrenizeMobile: [],
            databahanAktif2BioScFibrenizeMobile: [],
            databahanAktif3BioScFibrenizeMobile: [],
            moreManfaatProdukBioScFibrenizeMobile: [],

            dataProdukBioInflaviaMobile: [],
            classProdukBioInflavia: [],
            databahanAktif1BioInflaviaMobile: [],
            databahanAktif2BioInflaviaMobile: [],
            databahanAktif3BioInflaviaMobile: [],
            moreManfaatProdukBioInflaviaMobile: [],

            dataProdukBioDiabeguardMobile: [],
            classProdukBioDiabeguard: [],
            databahanAktif1BioDiabeguardMobile: [],
            databahanAktif2BioDiabeguardMobile: [],
            databahanAktif3BioDiabeguardMobile: [],
            moreManfaatProdukBioDiabeguardMobile: [],

            dataProdukKisekiMobile: [],
            classProdukKiseki: [],
            databahanAktif1KisekiMobile: [],
            databahanAktif2KisekiMobile: [],
            databahanAktif3KisekiMobile: [],
            moreManfaatProdukKisekiMobile: [],

            dataProdukBioOlleviaMobile: [],
            classProdukBioOllevia: [],
            databahanAktif1BioOlleviaMobile: [],
            databahanAktif2BioOlleviaMobile: [],
            databahanAktif3BioOlleviaMobile: [],
            moreManfaatProdukBioOlleviaMobile: [],

            dataProdukBioHypertenguardMobile: [],
            classProdukBioHypertenguard: [],
            databahanAktif1BioHypertenguardMobile: [],
            databahanAktif2BioHypertenguardMobile: [],
            databahanAktif3BioHypertenguardMobile: [],
            moreManfaatProdukBioHypertenguardMobile: [],

            dataProdukBioAdvanceMobile: [],
            classProdukBioAdvance: [],
            databahanAktif1BioAdvanceMobile: [],
            databahanAktif2BioAdvanceMobile: [],
            databahanAktif3BioAdvanceMobile: [],
            moreManfaatProdukBioAdvanceMobile: [],

            dataProdukBioBeneviaMobile: [],
            classProdukBioBenevia: [],
            databahanAktif1BioBeneviaMobile: [],
            databahanAktif2BioBeneviaMobile: [],
            databahanAktif3BioBeneviaMobile: [],
            moreManfaatProdukBioBeneviaMobile: [],

            dataProdukBioFassielMobile: [],
            classProdukBioFassiel: [],
            databahanAktif1BioFassielMobile: [],
            databahanAktif2BioFassielMobile: [],
            databahanAktif3BioFassielMobile: [],
            moreManfaatProdukBioFassielMobile: [],

            dataProdukBioSiflevioMobile: [],
            classProdukBioSiflevio: [],
            databahanAktif1BioSiflevioMobile: [],
            databahanAktif2BioSiflevioMobile: [],
            databahanAktif3BioSiflevioMobile: [],
            moreManfaatProdukBioSiflevioMobile: [],

            dataProdukBioFitMobile: [],
            classProdukBioFit: [],
            databahanAktif1BioFitMobile: [],
            databahanAktif2BioFitMobile: [],
            databahanAktif3BioFitMobile: [],
            moreManfaatProdukBioFitMobile: [],

            dataProdukBioRawgenicMobile: [],
            classProdukBioRawgenic: [],
            databahanAktif1BioRawgenicMobile: [],
            databahanAktif2BioRawgenicMobile: [],
            databahanAktif3BioRawgenicMobile: [],
            moreManfaatProdukBioRawgenicMobile: [],

            dataProdukBioBraceletMobile: [],
            classProdukBioBracelet: [],
            databahanAktif1BioBraceletMobile: [],
            databahanAktif2BioBraceletMobile: [],
            databahanAktif3BioBraceletMobile: [],
            moreManfaatProdukBioBraceletMobile: [],

            dataProdukBioBeadsMobile: [],
            classProdukBioBeads: [],
            databahanAktif1BioBeadsMobile: [],
            databahanAktif2BioBeadsMobile: [],
            databahanAktif3BioBeadsMobile: [],
            moreManfaatProdukBioBeadsMobile: [],

            showManfaatProdukAppleScPlusMobile: 0,
            showManfaatProdukBioMildMobile: 0,
            showManfaatProdukBioMildCapsule: 0,
            showManfaatProdukBioGoldMobile: 0,
            showManfaatProdukBioFibrenizeMobile: 0,
            showManfaatProdukBioInflaviaMobile: 0,

            showManfaatProdukBioAdvanceMobile: 0,
            showManfaatProdukBioBeneviaMobile: 0,
            showManfaatProdukBioFassielMobile: 0,
            showManfaatProdukBioSiflevioMobile: 0,

            showManfaatProdukBioFitMobile: 0,
            showManfaatProdukBioRawgenicMobile: 0,
            showManfaatProdukBioBracelet: 0,
            showManfaatProdukBioBeadsMobile: 0,

            showManfaatProdukBioDiabeguardMobile: 0,
            showManfaatProdukKisekiMobile: 0,
            showManfaatProdukBioOlleviaMobile: 0,
            showManfaatProdukBioHypertenguardMobile: 0,
        };
    },
    mounted() {
        this.bahasa = localStorage.getItem("bahasa");

        axios.get(`../../lang_${this.bahasa}.json`).then((response) => {
            this.data.produkTitle = response.data.data[7].produk[0].produkTitle;
        });

        this.displayContent = 1;

        document.getElementById("showProductDesktop1").style.display = "none";
        document.getElementById("showProductDesktop2").style.display = "none";
        document.getElementById("showProductDesktop3").style.display = "none";
        document.getElementById("showProductDesktop4").style.display = "none";

        document.getElementById("showLoadingProductDesktop1").style.display =
            "block";
        document.getElementById("showLoadingProductDesktop2").style.display =
            "block";
        document.getElementById("showLoadingProductDesktop3").style.display =
            "block";
        document.getElementById("showLoadingProductDesktop4").style.display =
            "block";

        document.getElementById("showProductTablet1").style.display = "none";
        document.getElementById("showProductTablet2").style.display = "none";
        document.getElementById("showProductTablet3").style.display = "none";
        document.getElementById("showProductTablet4").style.display = "none";

        document.getElementById("showLoadingProductTablet1").style.display =
            "block";
        document.getElementById("showLoadingProductTablet2").style.display =
            "block";
        document.getElementById("showLoadingProductTablet3").style.display =
            "block";
        document.getElementById("showLoadingProductTablet4").style.display =
            "block";

        document.getElementById("showProductMobile1").style.display = "none";
        document.getElementById("showProductMobile2").style.display = "none";
        document.getElementById("showProductMobile3").style.display = "none";
        document.getElementById("showProductMobile4").style.display = "none";

        document.getElementById("showLoadingProductMobile1").style.display =
            "block";
        document.getElementById("showLoadingProductMobile2").style.display =
            "block";
        document.getElementById("showLoadingProductMobile3").style.display =
            "block";
        document.getElementById("showLoadingProductMobile4").style.display =
            "block";
    },
    // created() {
    //   this.interval = setInterval(() => this.value = 100, 5000);
    // },
    methods: {
        onImgLoadHealth(e) {
            console.log(e);
            document.getElementById("showProductDesktop1").style.display = "block";
            document.getElementById("showProductDesktop2").style.display = "block";
            document.getElementById("showProductDesktop3").style.display = "block";
            document.getElementById("showProductDesktop4").style.display = "block";

            document.getElementById("showLoadingProductDesktop1").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop2").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop3").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop4").style.display =
                "none";

            document.getElementById("showProductTablet1").style.display = "block";
            document.getElementById("showProductTablet2").style.display = "block";
            document.getElementById("showProductTablet3").style.display = "block";
            document.getElementById("showProductTablet4").style.display = "block";

            document.getElementById("showLoadingProductTablet1").style.display =
                "none";
            document.getElementById("showLoadingProductTablet2").style.display =
                "none";
            document.getElementById("showLoadingProductTablet3").style.display =
                "none";
            document.getElementById("showLoadingProductTablet4").style.display =
                "none";

            document.getElementById("showProductMobile1").style.display = "block";
            document.getElementById("showProductMobile2").style.display = "block";
            document.getElementById("showProductMobile3").style.display = "block";
            document.getElementById("showProductMobile4").style.display = "block";

            document.getElementById("showLoadingProductMobile1").style.display =
                "none";
            document.getElementById("showLoadingProductMobile2").style.display =
                "none";
            document.getElementById("showLoadingProductMobile3").style.display =
                "none";
            document.getElementById("showLoadingProductMobile4").style.display =
                "none";
        },
        onImgLoadBeauty(e) {
            console.log(e);
            document.getElementById("showProductDesktop1").style.display = "block";
            document.getElementById("showProductDesktop2").style.display = "block";
            document.getElementById("showProductDesktop3").style.display = "block";
            document.getElementById("showProductDesktop4").style.display = "block";

            document.getElementById("showLoadingProductDesktop1").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop2").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop3").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop4").style.display =
                "none";

            document.getElementById("showProductTablet1").style.display = "block";
            document.getElementById("showProductTablet2").style.display = "block";
            document.getElementById("showProductTablet3").style.display = "block";
            document.getElementById("showProductTablet4").style.display = "block";

            document.getElementById("showLoadingProductTablet1").style.display =
                "none";
            document.getElementById("showLoadingProductTablet2").style.display =
                "none";
            document.getElementById("showLoadingProductTablet3").style.display =
                "none";
            document.getElementById("showLoadingProductTablet4").style.display =
                "none";

            document.getElementById("showProductMobile1").style.display = "block";
            document.getElementById("showProductMobile2").style.display = "block";
            document.getElementById("showProductMobile3").style.display = "block";
            document.getElementById("showProductMobile4").style.display = "block";

            document.getElementById("showLoadingProductMobile1").style.display =
                "none";
            document.getElementById("showLoadingProductMobile2").style.display =
                "none";
            document.getElementById("showLoadingProductMobile3").style.display =
                "none";
            document.getElementById("showLoadingProductMobile4").style.display =
                "none";
        },
        onImgLoadWeight(e) {
            console.log(e);
            document.getElementById("showProductDesktop1").style.display = "block";
            document.getElementById("showProductDesktop2").style.display = "block";
            document.getElementById("showProductDesktop3").style.display = "block";
            document.getElementById("showProductDesktop4").style.display = "block";

            document.getElementById("showLoadingProductDesktop1").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop2").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop3").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop4").style.display =
                "none";

            document.getElementById("showProductTablet1").style.display = "block";
            document.getElementById("showProductTablet2").style.display = "block";
            document.getElementById("showProductTablet3").style.display = "block";
            document.getElementById("showProductTablet4").style.display = "block";

            document.getElementById("showLoadingProductTablet1").style.display =
                "none";
            document.getElementById("showLoadingProductTablet2").style.display =
                "none";
            document.getElementById("showLoadingProductTablet3").style.display =
                "none";
            document.getElementById("showLoadingProductTablet4").style.display =
                "none";

            document.getElementById("showProductMobile1").style.display = "block";
            document.getElementById("showProductMobile2").style.display = "block";
            document.getElementById("showProductMobile3").style.display = "block";
            document.getElementById("showProductMobile4").style.display = "block";

            document.getElementById("showLoadingProductMobile1").style.display =
                "none";
            document.getElementById("showLoadingProductMobile2").style.display =
                "none";
            document.getElementById("showLoadingProductMobile3").style.display =
                "none";
            document.getElementById("showLoadingProductMobile4").style.display =
                "none";
        },
        onImgLoadAcc(e) {
            console.log(e);
            document.getElementById("showProductDesktop1").style.display = "block";
            document.getElementById("showProductDesktop2").style.display = "block";
            document.getElementById("showProductDesktop3").style.display = "block";
            document.getElementById("showProductDesktop4").style.display = "block";

            document.getElementById("showLoadingProductDesktop1").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop2").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop3").style.display =
                "none";
            document.getElementById("showLoadingProductDesktop4").style.display =
                "none";

            document.getElementById("showProductTablet1").style.display = "block";
            document.getElementById("showProductTablet2").style.display = "block";
            document.getElementById("showProductTablet3").style.display = "block";
            document.getElementById("showProductTablet4").style.display = "block";

            document.getElementById("showLoadingProductTablet1").style.display =
                "none";
            document.getElementById("showLoadingProductTablet2").style.display =
                "none";
            document.getElementById("showLoadingProductTablet3").style.display =
                "none";
            document.getElementById("showLoadingProductTablet4").style.display =
                "none";

            document.getElementById("showProductMobile1").style.display = "block";
            document.getElementById("showProductMobile2").style.display = "block";
            document.getElementById("showProductMobile3").style.display = "block";
            document.getElementById("showProductMobile4").style.display = "block";

            document.getElementById("showLoadingProductMobile1").style.display =
                "none";
            document.getElementById("showLoadingProductMobile2").style.display =
                "none";
            document.getElementById("showLoadingProductMobile3").style.display =
                "none";
            document.getElementById("showLoadingProductMobile4").style.display =
                "none";
        },

        // desktop
        clickProduct(e) {
            e.preventDefault;
            console.log(e);

            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                if (this.value == 100) {
                    this.value = 0;
                } else {
                    setInterval(() => (this.value = 100), 2000);
                }

                if (e == "applesc") {
                    this.dataProduk = response.data.data[0];
                    // console.log(this.dataProduk.manfaat)

                    this.classProduk = response.data.data[0].class;

                    this.databahanAktif1 = response.data.data[0].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[0].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[0].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[0].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailApple == 0) {
                        this.displayDetailApple = 1;
                    } else {
                        this.displayDetailApple = 0;
                    }

                    if (this.displayDetailAppleMobile == 0) {
                        this.displayDetailAppleMobile = 1;
                    } else {
                        this.displayDetailAppleMobile = 0;
                    }
                } else if (e == "applescplus") {
                    this.dataProduk = response.data.data[1];

                    this.classProduk = response.data.data[1].class;

                    this.databahanAktif1 = response.data.data[1].bahan_aktif.slice(0, 3);
                    this.databahanAktif2 = response.data.data[1].bahan_aktif.slice(3, 6);
                    this.databahanAktif3 = response.data.data[1].bahan_aktif.slice(6, 8);

                    this.moreManfaatProdukApplePlus = response.data.data[1].manfaat.slice(
                        0,
                        3
                    );

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailApplePlus == 0) {
                        this.displayDetailApplePlus = 1;
                    } else {
                        this.displayDetailApplePlus = 0;
                    }

                    if (this.displayDetailApplePlusMobile == 0) {
                        this.displayDetailApplePlusMobile = 1;
                    } else {
                        this.displayDetailApplePlusMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biofit") {
                    this.dataProduk = response.data.data[2];

                    this.classProduk = response.data.data[2].class;

                    this.databahanAktif1 = response.data.data[2].bahan_aktif.slice(0, 1);
                    this.databahanAktif2 = response.data.data[2].bahan_aktif.slice(1, 2);
                    this.databahanAktif3 = response.data.data[2].bahan_aktif.slice(2, 3);

                    this.moreManfaatProduk = response.data.data[2].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioFit == 0) {
                        this.displayDetailBioFit = 1;
                    } else {
                        this.displayDetailBioFit = 0;
                    }

                    if (this.displayDetailBioFitMobile == 0) {
                        this.displayDetailBioFitMobile = 1;
                    } else {
                        this.displayDetailBioFitMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biomild") {
                    this.dataProduk = response.data.data[3];

                    this.classProduk = response.data.data[3].class;

                    this.databahanAktif1 = response.data.data[3].bahan_aktif.slice(0, 1);
                    this.databahanAktif2 = response.data.data[3].bahan_aktif.slice(1, 2);
                    this.databahanAktif3 = response.data.data[3].bahan_aktif.slice(2, 4);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioMild == 0) {
                        this.displayDetailBioMild = 1;
                    } else {
                        this.displayDetailBioMild = 0;
                    }

                    if (this.displayDetailBioMildMobile == 0) {
                        this.displayDetailBioMildMobile = 1;
                    } else {
                        this.displayDetailBioMildMobile = 0;
                    }

                    this.moreManfaatProduk = response.data.data[3].manfaat.slice(0, 3);

                    console.log(response.data.data[0].manfaat.slice(0, 3));

                    // console.log(this.dataProduk)
                } else if (e == "biobenevia") {
                    this.dataProduk = response.data.data[4];

                    this.classProduk = response.data.data[4].class;

                    this.databahanAktif1 = response.data.data[4].bahan_aktif.slice(0, 3);
                    this.databahanAktif2 = response.data.data[4].bahan_aktif.slice(3, 6);
                    this.databahanAktif3 = response.data.data[4].bahan_aktif.slice(6, 8);

                    this.displayBioBenevia = 1;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioBenevia == 0) {
                        this.displayDetailBioBenevia = 1;
                    } else {
                        this.displayDetailBioBenevia = 0;
                    }

                    if (this.displayDetailBioBeneviaMobile == 0) {
                        this.displayDetailBioBeneviaMobile = 1;
                    } else {
                        this.displayDetailBioBeneviaMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "kembaliBenevia") {
                    this.dataProduk = response.data.data[4];

                    this.classProduk = response.data.data[4].class;

                    this.databahanAktif1 = response.data.data[4].bahan_aktif.slice(0, 3);
                    this.databahanAktif2 = response.data.data[4].bahan_aktif.slice(3, 6);
                    this.databahanAktif3 = response.data.data[4].bahan_aktif.slice(6, 8);

                    this.displayBioBenevia = 1;

                    // console.log(this.dataProduk)
                } else if (e == "bioadvanced") {
                    this.dataProduk = response.data.data[5];

                    this.classProduk = response.data.data[5].class;

                    this.databahanAktif1 = response.data.data[5].bahan_aktif.slice(0, 3);
                    this.databahanAktif2 = response.data.data[5].bahan_aktif.slice(3, 6);
                    this.databahanAktif3 = response.data.data[5].bahan_aktif.slice(6, 8);

                    this.moreManfaatProduk = response.data.data[5].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioAdvanced == 0) {
                        this.displayDetailBioAdvanced = 1;
                    } else {
                        this.displayDetailBioAdvanced = 0;
                    }

                    if (this.displayDetailBioAdvancedMobile == 0) {
                        this.displayDetailBioAdvancedMobile = 1;
                    } else {
                        this.displayDetailBioAdvancedMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "beneviaTooth") {
                    this.dataProduk = response.data.data[6];

                    this.classProduk = response.data.data[6].class;

                    this.databahanAktif1 = response.data.data[6].bahan_aktif.slice(0, 1);
                    this.databahanAktif2 = response.data.data[6].bahan_aktif.slice(1, 2);
                    this.databahanAktif3 = response.data.data[6].bahan_aktif.slice(2, 4);

                    this.moreManfaatProduk = response.data.data[6].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    // console.log(this.dataProduk)
                } else if (e == "beneviaBody") {
                    this.dataProduk = response.data.data[7];

                    this.classProduk = response.data.data[7].class;

                    this.databahanAktif1 = response.data.data[7].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[7].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[7].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[7].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    // console.log(this.dataProduk)
                } else if (e == "beneviaFacial") {
                    this.dataProduk = response.data.data[8];

                    this.classProduk = response.data.data[8].class;

                    this.databahanAktif1 = response.data.data[8].bahan_aktif.slice(0, 1);
                    this.databahanAktif2 = response.data.data[8].bahan_aktif.slice(1, 2);
                    this.databahanAktif3 = response.data.data[8].bahan_aktif.slice(2, 4);

                    this.moreManfaatProduk = response.data.data[8].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    // console.log(this.dataProduk)
                } else if (e == "beneviaCond") {
                    this.dataProduk = response.data.data[9];

                    this.classProduk = response.data.data[9].class;

                    this.databahanAktif1 = response.data.data[9].bahan_aktif.slice(0, 1);
                    this.databahanAktif2 = response.data.data[9].bahan_aktif.slice(1, 2);
                    this.databahanAktif3 = response.data.data[9].bahan_aktif.slice(2, 4);

                    this.moreManfaatProduk = response.data.data[9].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    // console.log(this.dataProduk)
                } else if (e == "beneviaShampo") {
                    this.dataProduk = response.data.data[10];

                    this.classProduk = response.data.data[10].class;

                    this.databahanAktif1 = response.data.data[10].bahan_aktif.slice(0, 3);
                    this.databahanAktif2 = response.data.data[10].bahan_aktif.slice(3, 6);
                    this.databahanAktif3 = response.data.data[10].bahan_aktif.slice(6, 8);

                    this.moreManfaatProduk = response.data.data[10].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    // console.log(this.dataProduk)
                } else if (e == "biogold") {
                    this.dataProduk = response.data.data[11];

                    this.classProduk = response.data.data[11].class;

                    this.databahanAktif1 = response.data.data[11].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[11].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[11].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[11].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioGold == 0) {
                        this.displayDetailBioGold = 1;
                    } else {
                        this.displayDetailBioGold = 0;
                    }

                    if (this.displayDetailBioGoldMobile == 0) {
                        this.displayDetailBioGoldMobile = 1;
                    } else {
                        this.displayDetailBioGoldMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biofassiel") {
                    this.dataProduk = response.data.data[12];

                    this.classProduk = response.data.data[12].class;

                    this.databahanAktif1 = response.data.data[12].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[12].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[12].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[12].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioFassiel == 0) {
                        this.displayDetailBioFassiel = 1;
                    } else {
                        this.displayDetailBioFassiel = 0;
                    }

                    if (this.displayDetailBioFassielMobile == 0) {
                        this.displayDetailBioFassielMobile = 1;
                    } else {
                        this.displayDetailBioFassielMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biomildcapsule") {
                    this.dataProduk = response.data.data[13];

                    this.classProduk = response.data.data[13].class;

                    this.databahanAktif1 = response.data.data[13].bahan_aktif.slice(0, 1);
                    this.databahanAktif2 = response.data.data[13].bahan_aktif.slice(1, 2);
                    this.databahanAktif3 = response.data.data[13].bahan_aktif.slice(2, 4);

                    this.moreManfaatProduk = response.data.data[13].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioMildCapsule == 0) {
                        this.displayDetailBioMildCapsule = 1;
                    } else {
                        this.displayDetailBioMildCapsule = 0;
                    }

                    if (this.displayDetailBioMildCapsuleMobile == 0) {
                        this.displayDetailBioMildCapsuleMobile = 1;
                    } else {
                        this.displayDetailBioMildCapsuleMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biofibrenize") {
                    this.dataProduk = response.data.data[14];

                    this.classProduk = response.data.data[14].class;

                    this.databahanAktif1 = response.data.data[14].bahan_aktif.slice(0, 3);
                    this.databahanAktif2 = response.data.data[14].bahan_aktif.slice(3, 6);
                    this.databahanAktif3 = response.data.data[14].bahan_aktif.slice(6, 8);

                    this.moreManfaatProduk = response.data.data[14].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioFibrenize == 0) {
                        this.displayDetailBioFibrenize = 1;
                    } else {
                        this.displayDetailBioFibrenize = 0;
                    }

                    if (this.displayDetailBioFibrenizeMobile == 0) {
                        this.displayDetailBioFibrenizeMobile = 1;
                    } else {
                        this.displayDetailBioFibrenizeMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biorawgenic") {
                    this.dataProduk = response.data.data[15];

                    this.classProduk = response.data.data[15].class;

                    this.databahanAktif1 = response.data.data[15].bahan_aktif.slice(0, 3);
                    this.databahanAktif2 = response.data.data[15].bahan_aktif.slice(3, 6);
                    this.databahanAktif3 = response.data.data[15].bahan_aktif.slice(6, 8);

                    this.moreManfaatProduk = response.data.data[15].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioRawgenic == 0) {
                        this.displayDetailBioRawgenic = 1;
                    } else {
                        this.displayDetailBioRawgenic = 0;
                    }

                    if (this.displayDetailBioRawgenicMobile == 0) {
                        this.displayDetailBioRawgenicMobile = 1;
                    } else {
                        this.displayDetailBioRawgenicMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "bioinflavia") {
                    this.dataProduk = response.data.data[16];

                    this.classProduk = response.data.data[16].class;

                    this.databahanAktif1 = response.data.data[16].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[16].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[16].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[16].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioInflavia == 0) {
                        this.displayDetailBioInflavia = 1;
                    } else {
                        this.displayDetailBioInflavia = 0;
                    }

                    if (this.displayDetailBioInflaviaMobile == 0) {
                        this.displayDetailBioInflaviaMobile = 1;
                    } else {
                        this.displayDetailBioInflaviaMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biosiflevio") {
                    this.dataProduk = response.data.data[17];

                    this.classProduk = response.data.data[17].class;

                    this.databahanAktif1 = response.data.data[17].bahan_aktif.slice(0, 1);
                    this.databahanAktif2 = response.data.data[17].bahan_aktif.slice(1, 2);
                    this.databahanAktif3 = response.data.data[17].bahan_aktif.slice(2, 4);

                    this.moreManfaatProduk = response.data.data[17].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioSiflevio == 0) {
                        this.displayDetailBioSiflevio = 1;
                    } else {
                        this.displayDetailBioSiflevio = 0;
                    }

                    if (this.displayDetailBioSiflevioMobile == 0) {
                        this.displayDetailBioSiflevioMobile = 1;
                    } else {
                        this.displayDetailBioSiflevioMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "bionadboost") {
                    this.dataProduk = response.data.data[18];
                    // console.log(this.dataProduk.manfaat)

                    this.classProduk = response.data.data[18].class;

                    this.databahanAktif1 = response.data.data[18].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[18].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[18].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[18].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioNADBoost == 0) {
                        this.displayDetailBioNADBoost = 1;
                    } else {
                        this.displayDetailBioNADBoost = 0;
                    }

                    if (this.displayDetailBioNADBoostMobile == 0) {
                        this.displayDetailBioNADBoostMobile = 1;
                    } else {
                        this.displayDetailBioNADBoostMobile = 0;
                    }
                } else if (e == "biobracelet") {
                    this.dataProduk = response.data.data[19];
                    // console.log(this.dataProduk.manfaat)

                    this.classProduk = response.data.data[19].class;

                    this.databahanAktif1 = response.data.data[19].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[19].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[19].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[19].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioBracelet == 0) {
                        this.displayDetailBioBracelet = 1;
                    } else {
                        this.displayDetailBioBracelet = 0;
                    }

                    if (this.displayDetailBioBraceletMobile == 0) {
                        this.displayDetailBioBraceletMobile = 1;
                    } else {
                        this.displayDetailBioBraceletMobile = 0;
                    }
                } else if (e == "bioneclace") {
                    this.dataProduk = response.data.data[20];
                    // console.log(this.dataProduk.manfaat)

                    this.classProduk = response.data.data[20].class;

                    this.databahanAktif1 = response.data.data[20].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[20].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[20].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[20].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioNeclace == 0) {
                        this.displayDetailBioNeclace = 1;
                    } else {
                        this.displayDetailBioNeclace = 0;
                    }

                    if (this.displayDetailBioNeclaceMobile == 0) {
                        this.displayDetailBioNeclaceMobile = 1;
                    } else {
                        this.displayDetailBioNeclaceMobile = 0;
                    }
                } else if (e == "biobeads") {
                    this.dataProduk = response.data.data[21];
                    // console.log(this.dataProduk.manfaat)

                    this.classProduk = response.data.data[21].class;

                    this.databahanAktif1 = response.data.data[21].bahan_aktif.slice(0, 4);
                    this.databahanAktif2 = response.data.data[21].bahan_aktif.slice(4, 8);
                    this.databahanAktif3 = response.data.data[21].bahan_aktif.slice(
                        8,
                        12
                    );

                    this.moreManfaatProduk = response.data.data[21].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailKiseki = 0),
                        (this.displayDetailBioDiabeguard = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioBeads == 0) {
                        this.displayDetailBioBeads = 1;
                    } else {
                        this.displayDetailBioBeads = 0;
                    }

                    if (this.displayDetailBioBeadsMobile == 0) {
                        this.displayDetailBioBeadsMobile = 1;
                    } else {
                        this.displayDetailBioBeadsMobile = 0;
                    }
                } else if (e == "biodiabeguard") {
                    this.dataProduk = response.data.data[22];

                    this.classProduk = response.data.data[22].class;

                    this.databahanAktif1 = response.data.data[22].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[22].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[22].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[22].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;
                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioInflavia = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0),
                        (this.displayDetailKiseki = 0);
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioDiabeguard == 0) {
                        this.displayDetailBioDiabeguard = 1;
                    } else {
                        this.displayDetailBioDiabeguard = 0;
                    }

                    if (this.displayDetailBioDiabeguardMobile == 0) {
                        this.displayDetailBioDiabeguardMobile = 1;
                    } else {
                        this.displayDetailBioDiabeguardMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "kiseki") {
                    this.dataProduk = response.data.data[23];

                    this.classProduk = response.data.data[23].class;

                    this.databahanAktif1 = response.data.data[23].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[23].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[23].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[23].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0);
                    this.displayDetailBioDiabeguard = 0;
                    this.displayDetailBioOllevia = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailKiseki == 0) {
                        this.displayDetailKiseki = 1;
                    } else {
                        this.displayDetailKiseki = 0;
                    }

                    if (this.displayDetailKisekiMobile == 0) {
                        this.displayDetailKisekiMobile = 1;
                    } else {
                        this.displayDetailKisekiMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "bioollevia") {
                    this.dataProduk = response.data.data[24];

                    this.classProduk = response.data.data[24].class;

                    this.databahanAktif1 = response.data.data[24].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[24].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[24].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[24].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0);
                    this.displayDetailBioDiabeguard = 0;
                    this.displayDetailKiseki = 0;
                    this.displayDetailBioHypertenguard = 0;

                    if (this.displayDetailBioOllevia == 0) {
                        this.displayDetailBioOllevia = 1;
                    } else {
                        this.displayDetailBioOllevia = 0;
                    }

                    if (this.displayDetailBioOlleviaMobile == 0) {
                        this.displayDetailBioOlleviaMobile = 1;
                    } else {
                        this.displayDetailBioOlleviaMobile = 0;
                    }

                    // console.log(this.dataProduk)
                } else if (e == "biohypertenguard") {
                    this.dataProduk = response.data.data[25];

                    this.classProduk = response.data.data[25].class;

                    this.databahanAktif1 = response.data.data[25].bahan_aktif.slice(0, 2);
                    this.databahanAktif2 = response.data.data[25].bahan_aktif.slice(2, 4);
                    this.databahanAktif3 = response.data.data[25].bahan_aktif.slice(4, 6);

                    this.moreManfaatProduk = response.data.data[25].manfaat.slice(0, 3);

                    this.displayBioBenevia = 0;

                    (this.displayDetailApple = 0),
                        (this.displayDetailBioNADBoost = 0),
                        (this.displayDetailApplePlus = 0),
                        (this.displayDetailBioMild = 0),
                        (this.displayDetailBioMildCapsule = 0),
                        (this.displayDetailBioGold = 0),
                        (this.displayDetailBioFibrenize = 0),
                        (this.displayDetailBioAdvanced = 0),
                        (this.displayDetailBioBenevia = 0),
                        (this.displayDetailBioFassiel = 0),
                        (this.displayDetailBioSiflevio = 0),
                        (this.displayDetailBioFit = 0),
                        (this.displayDetailBioRawgenic = 0),
                        (this.displayDetailBioBracelet = 0),
                        (this.displayDetailBioNeclace = 0),
                        (this.displayDetailBioBeads = 0);
                    this.displayDetailBioDiabeguard = 0;
                    this.displayDetailKiseki = 0;
                    this.displayDetailBioOllevia = 0;

                    if (this.displayDetailBioHypertenguard == 0) {
                        this.displayDetailBioHypertenguard = 1;
                    } else {
                        this.displayDetailBioHypertenguard = 0;
                    }

                    if (this.displayDetailBioHypertenguardMobile == 0) {
                        this.displayDetailBioHypertenguardMobile = 1;
                    } else {
                        this.displayDetailBioHypertenguardMobile = 0;
                    }

                    // console.log(this.dataProduk)
                }
            });
        },
        // end desktop

        // mobile
        clickProductApplescMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukAppleScMobile = response.data.data[0];
                // console.log(this.dataProduk.manfaat)

                this.classProdukAppleSc = response.data.data[0].class;

                this.databahanAktif1AppleScMobile =
                    response.data.data[0].bahan_aktif.slice(0, 2);
                this.databahanAktif2AppleScNobile =
                    response.data.data[0].bahan_aktif.slice(2, 4);
                this.databahanAktif3AppleScMobile =
                    response.data.data[0].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukAppleScMobile =
                    response.data.data[0].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailAppleMobile == 0) {
                    this.displayDetailAppleMobile = 1;
                } else {
                    this.displayDetailAppleMobile = 0;
                }
            });
        },
        clickProductBioNADBoostMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioNADBoostMobile = response.data.data[18];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioNADBoost = response.data.data[18].class;

                this.databahanAktif1BioNADBoostMobile =
                    response.data.data[18].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioNADBoostMobile =
                    response.data.data[18].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioNADBoostMobile =
                    response.data.data[18].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioNADBoostMobile =
                    response.data.data[18].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioNADBoostMobile == 0) {
                    this.displayDetailBioNADBoostMobile = 1;
                } else {
                    this.displayDetailBioNADBoostMobile = 0;
                }
            });
        },
        clickProductApplePlusMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukAppleScPlusMobile = response.data.data[1];
                // console.log(this.dataProduk.manfaat)

                this.classProdukAppleScPlus = response.data.data[1].class;

                this.databahanAktif1AppleScPlusMobile =
                    response.data.data[1].bahan_aktif.slice(0, 2);
                this.databahanAktif2AppleScPlusMobile =
                    response.data.data[1].bahan_aktif.slice(2, 4);
                this.databahanAktif3AppleScPlusMobile =
                    response.data.data[1].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukAppleScPlusMobile =
                    response.data.data[1].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailApplePlusMobile == 0) {
                    this.displayDetailApplePlusMobile = 1;
                } else {
                    this.displayDetailApplePlusMobile = 0;
                }
            });
        },
        clickProductBioMildeMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioScMildMobile = response.data.data[3];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioScMild = response.data.data[3].class;

                this.databahanAktif1BioScMildMobile =
                    response.data.data[3].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioScMildMobile =
                    response.data.data[3].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioScMildMobile =
                    response.data.data[3].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioScMildMobile =
                    response.data.data[3].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioMildMobile == 0) {
                    this.displayDetailBioMildMobile = 1;
                } else {
                    this.displayDetailBioMildMobile = 0;
                }
            });
        },
        clickProductBioMildCapsuleMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioScMildCapsuleMobile = response.data.data[13];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioScMildCapsule = response.data.data[13].class;

                this.databahanAktif1BioScMildCapsuleMobile =
                    response.data.data[13].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioScMildCapsuleMobile =
                    response.data.data[13].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioScMildCapsuleMobile =
                    response.data.data[13].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioScMildCapsuleMobile =
                    response.data.data[13].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioMildCapsuleMobile == 0) {
                    this.displayDetailBioMildCapsuleMobile = 1;
                } else {
                    this.displayDetailBioMildCapsuleMobile = 0;
                }
            });
        },
        clickProductBioGoldMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioScGoldMobile = response.data.data[11];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioScGold = response.data.data[11].class;

                this.databahanAktif1BioScGoldMobile =
                    response.data.data[11].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioScGoldMobile =
                    response.data.data[11].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioScGoldMobile =
                    response.data.data[11].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioScGoldMobile =
                    response.data.data[11].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioGoldMobile == 0) {
                    this.displayDetailBioGoldMobile = 1;
                } else {
                    this.displayDetailBioGoldMobile = 0;
                }
            });
        },
        clickProductBioFibrenizeMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioScFibrenizeMobile = response.data.data[14];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioScFibrenize = response.data.data[14].class;

                this.databahanAktif1BioScFibrenizeMobile =
                    response.data.data[14].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioScFibrenizeMobile =
                    response.data.data[14].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioScFibrenizeMobile =
                    response.data.data[14].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioScFibrenizeMobile =
                    response.data.data[14].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioFibrenizeMobile == 0) {
                    this.displayDetailBioFibrenizeMobile = 1;
                } else {
                    this.displayDetailBioFibrenizeMobile = 0;
                }
            });
        },
        clickProductBioInflaviaMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioInflaviaMobile = response.data.data[16];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioInflavia = response.data.data[16].class;

                this.databahanAktif1BioInflaviaMobile =
                    response.data.data[16].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioInflaviaMobile =
                    response.data.data[16].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioInflaviaMobile =
                    response.data.data[16].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioInflaviaMobile =
                    response.data.data[16].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioInflaviaMobile == 0) {
                    this.displayDetailBioInflaviaMobile = 1;
                } else {
                    this.displayDetailBioInflaviaMobile = 0;
                }
            });
        },
        clickProductBioAdvanceMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioAdvanceMobile = response.data.data[5];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioAdvance = response.data.data[5].class;

                this.databahanAktif1BioAdvanceMobile =
                    response.data.data[5].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioAdvanceMobile =
                    response.data.data[5].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioAdvanceMobile =
                    response.data.data[5].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioAdvanceMobile =
                    response.data.data[5].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioAdvancedMobile == 0) {
                    this.displayDetailBioAdvancedMobile = 1;
                } else {
                    this.displayDetailBioAdvancedMobile = 0;
                }
            });
        },
        clickProductBioBeneviaMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioBeneviaMobile = response.data.data[4];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioBenevia = response.data.data[4].class;

                this.databahanAktif1BioBeneviaMobile =
                    response.data.data[4].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioBeneviaMobile =
                    response.data.data[4].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioBeneviaMobile =
                    response.data.data[4].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioBeneviaMobile =
                    response.data.data[4].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioBeneviaMobile == 0) {
                    this.displayDetailBioBeneviaMobile = 1;
                } else {
                    this.displayDetailBioBeneviaMobile = 0;
                }
            });
        },
        clickProductBioFassielMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioFassielMobile = response.data.data[12];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioFassiel = response.data.data[12].class;

                this.databahanAktif1BioFassielMobile =
                    response.data.data[12].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioFassielMobile =
                    response.data.data[12].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioFassielMobile =
                    response.data.data[12].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioFassielMobile =
                    response.data.data[12].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioFassielMobile == 0) {
                    this.displayDetailBioFassielMobile = 1;
                } else {
                    this.displayDetailBioFassielMobile = 0;
                }
            });
        },
        clickProductBioSiflevioMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioSiflevioMobile = response.data.data[17];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioSiflevio = response.data.data[17].class;

                this.databahanAktif1BioSiflevioMobile =
                    response.data.data[17].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioSiflevioMobile =
                    response.data.data[17].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioSiflevioMobile =
                    response.data.data[17].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioSiflevioMobile =
                    response.data.data[17].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioSiflevioMobile == 0) {
                    this.displayDetailBioSiflevioMobile = 1;
                } else {
                    this.displayDetailBioSiflevioMobile = 0;
                }
            });
        },
        clickProductBioFitMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioFitMobile = response.data.data[2];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioFit = response.data.data[2].class;

                this.databahanAktif1BioFitMobile =
                    response.data.data[2].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioFitMobile =
                    response.data.data[2].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioFitMobile =
                    response.data.data[2].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioFitMobile =
                    response.data.data[2].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioFitMobile == 0) {
                    this.displayDetailBioFitMobile = 1;
                } else {
                    this.displayDetailBioFitMobile = 0;
                }
            });
        },
        clickProductBioRawgenicMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioRawgenicMobile = response.data.data[15];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioRawgenic = response.data.data[15].class;

                this.databahanAktif1BioRawgenicMobile =
                    response.data.data[15].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioRawgenicMobile =
                    response.data.data[15].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioRawgenicMobile =
                    response.data.data[15].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioRawgenicMobile =
                    response.data.data[15].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioRawgenicMobile == 0) {
                    this.displayDetailBioRawgenicMobile = 1;
                } else {
                    this.displayDetailBioRawgenicMobile = 0;
                }
            });
        },
        clickProductBioBraceletMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioBraceletMobile = response.data.data[19];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioBracelet = response.data.data[19].class;

                this.databahanAktif1BioBraceletMobile =
                    response.data.data[19].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioBraceletMobile =
                    response.data.data[19].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioBraceletMobile =
                    response.data.data[19].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioBraceletMobile =
                    response.data.data[19].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioBraceletMobile == 0) {
                    this.displayDetailBioBraceletMobile = 1;
                } else {
                    this.displayDetailBioBraceletMobile = 0;
                }
            });
        },
        clickProductBioBeadsMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioBeadsMobile = response.data.data[21];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioBeads = response.data.data[21].class;

                this.databahanAktif1BioBeadsMobile =
                    response.data.data[21].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioBeadsMobile =
                    response.data.data[21].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioBeadsMobile =
                    response.data.data[21].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioBeadsMobile =
                    response.data.data[21].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioBeadsMobile == 0) {
                    this.displayDetailBioBeadsMobile = 1;
                } else {
                    this.displayDetailBioBeadsMobile = 0;
                }
            });
        },
        clickProductBioDiabeguardMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioDiabeguardMobile = response.data.data[22];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioDiabeguard = response.data.data[22].class;

                this.databahanAktif1BioDiabeguardMobile =
                    response.data.data[22].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioDiabeguardMobile =
                    response.data.data[22].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioDiabeguardMobile =
                    response.data.data[22].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioDiabeguardMobile =
                    response.data.data[22].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioDiabeguardMobile == 0) {
                    this.displayDetailBioDiabeguardMobile = 1;
                } else {
                    this.displayDetailBioDiabeguardMobile = 0;
                }
            });
        },
        clickProductKisekiMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukKisekiMobile = response.data.data[23];
                // console.log(this.dataProduk.manfaat)

                this.classProdukKiseki = response.data.data[23].class;

                this.databahanAktif1KisekiMobile =
                    response.data.data[23].bahan_aktif.slice(0, 2);
                this.databahanAktif2KisekiMobile =
                    response.data.data[23].bahan_aktif.slice(2, 4);
                this.databahanAktif3KisekiMobile =
                    response.data.data[23].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukKisekiMobile =
                    response.data.data[23].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailKisekiMobile == 0) {
                    this.displayDetailKisekiMobile = 1;
                } else {
                    this.displayDetailKisekiMobile = 0;
                }
            });
        },
        clickProductBioOlleviaMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioOlleviaMobile = response.data.data[24];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioOllevia = response.data.data[24].class;

                this.databahanAktif1BioOlleviaMobile =
                    response.data.data[24].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioOlleviaMobile =
                    response.data.data[24].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioOlleviaMobile =
                    response.data.data[24].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioOlleviaMobile =
                    response.data.data[24].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioOlleviaMobile == 0) {
                    this.displayDetailBioOlleviaMobile = 1;
                } else {
                    this.displayDetailBioOlleviaMobile = 0;
                }
            });
        },
        clickProductBioHypertenguardMobile() {
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
            axios.get(`../data_${this.bahasa}.json`).then((response) => {
                this.dataProdukBioHypertenguardMobile = response.data.data[25];
                // console.log(this.dataProduk.manfaat)

                this.classProdukBioHypertenguard = response.data.data[25].class;

                this.databahanAktif1BioHypertenguardMobile =
                    response.data.data[25].bahan_aktif.slice(0, 2);
                this.databahanAktif2BioHypertenguardMobile =
                    response.data.data[25].bahan_aktif.slice(2, 4);
                this.databahanAktif3BioHypertenguardMobile =
                    response.data.data[25].bahan_aktif.slice(4, 6);

                this.moreManfaatProdukBioHypertenguardMobile =
                    response.data.data[25].manfaat.slice(0, 3);

                this.displayBioBenevia = 0;

                if (this.displayDetailBioHypertenguardMobile == 0) {
                    this.displayDetailBioHypertenguardMobile = 1;
                } else {
                    this.displayDetailBioHypertenguardMobile = 0;
                }
            });
        },

        readMoreManfaatAppleScPlusMobile() {
            this.showManfaatProdukAppleScPlusMobile = 1;
        },
        kembaliManfaatAppleScPlusMobile() {
            this.showManfaatProdukAppleScPlusMobile = 0;
        },
        readMoreManfaatBioMildMobile() {
            this.showManfaatProdukBioMildMobile = 1;
        },
        kembaliManfaatBioMildMobile() {
            this.showManfaatProdukBioMildMobile = 0;
        },
        readMoreManfaatBioMildCapsule() {
            this.showManfaatProdukBioMildCapsule = 1;
        },
        kembaliManfaatBioMildCapsule() {
            this.showManfaatProdukBioMildCapsule = 0;
        },
        readMoreManfaatBioGoldMobile() {
            this.showManfaatProdukBioGoldMobile = 1;
        },
        kembaliManfaatBioGoldMobile() {
            this.showManfaatProdukBioGoldMobile = 0;
        },
        readMoreManfaatBioFibrenizeMobile() {
            this.showManfaatProdukBioFibrenizeMobile = 1;
        },
        kembaliManfaatBioFibrenizeMobile() {
            this.showManfaatProdukBioFibrenizeMobile = 0;
        },
        readMoreManfaatBioInflaviaMobile() {
            this.showManfaatProdukBioInflaviaMobile = 1;
        },
        kembaliManfaatBioInflaviaMobile() {
            this.showManfaatProdukBioInflaviaMobile = 0;
        },
        readMoreManfaatBioFitMobile() {
            this.showManfaatProdukBioFitMobile = 1;
        },
        kembaliManfaatBioFitMobile() {
            this.showManfaatProdukBioFitMobile = 0;
        },
        readMoreManfaatBioRawgenicMobile() {
            this.showManfaatProdukBioRawgenicMobile = 1;
        },
        kembaliManfaatBioRawgenicMobile() {
            this.showManfaatProdukBioRawgenicMobile = 0;
        },
        readMoreManfaatBioBeadsMobile() {
            this.showManfaatProdukBioBeadsMobile = 1;
        },
        kembaliManfaatBioBeadsMobile() {
            this.showManfaatProdukBioBeadsMobile = 0;
        },

        // end mobile

        manfaat() {
            this.displayContent = 0;
        },
        kembali() {
            this.displayContent = 1;
        },
        readMoreManfaat() {
            this.showManfaatProduk = 1;
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
        },
        kembaliManfaat() {
            this.showManfaatProduk = 0;
            if (this.value == 100) {
                this.value = 0;
            } else {
                setInterval(() => (this.value = 100), 2000);
            }
        },
        clickCarret(product) {
            (this.displayDetailApple = 0),
                (this.displayDetailBioNADBoost = 0),
                (this.displayDetailApplePlus = 0),
                (this.displayDetailBioMild = 0),
                (this.displayDetailBioMildCapsule = 0),
                (this.displayDetailBioGold = 0),
                (this.displayDetailBioFibrenize = 0),
                (this.displayDetailBioInflavia = 0),
                (this.displayDetailBioAdvanced = 0),
                (this.displayDetailBioBenevia = 0),
                (this.displayDetailBioFassiel = 0),
                (this.displayDetailBioSiflevio = 0),
                (this.displayDetailBioFit = 0),
                (this.displayDetailBioRawgenic = 0),
                (this.displayDetailBioBracelet = 0),
                (this.displayDetailBioNeclace = 0),
                (this.displayDetailBioBeads = 0),
                (this.displayDetailBioDiabeguard = 0),
                (this.displayDetailKiseki = 0),
                (this.displayDetailBioHypertenguard = 0);

            if (product == "Health") {
                if (this.carretIconHealth == "fa fa-caret-down") {
                    this.carretIconHealth = "fa fa-caret-up";
                    this.showHealth = 1;
                } else {
                    this.carretIconHealth = "fa fa-caret-down";
                    this.showHealth = 0;
                }
            } else if (product == "Beauty") {
                if (this.carretIconBeauty == "fa fa-caret-down") {
                    this.carretIconBeauty = "fa fa-caret-up";
                    this.showBeauty = 1;
                } else {
                    this.carretIconBeauty = "fa fa-caret-down";
                    this.showBeauty = 0;
                }
            } else if (product == "BodyWeight") {
                if (this.carretIconBodyWeight == "fa fa-caret-down") {
                    this.carretIconBodyWeight = "fa fa-caret-up";
                    this.showBodyWeight = 1;
                } else {
                    this.carretIconBodyWeight = "fa fa-caret-down";
                    this.showBodyWeight = 0;
                }
            } else if (product == "Accessories") {
                if (this.carretIconAccessories == "fa fa-caret-down") {
                    this.carretIconAccessories = "fa fa-caret-up";
                    this.showAccessories = 1;
                } else {
                    this.carretIconAccessories = "fa fa-caret-down";
                    this.showAccessories = 0;
                }
            }
        },
    },
};
</script>

<style></style>
